import useFirebaseAuth from "./useFirebaseAuth";
import {useCallback} from "react";

const baseUrl = "https://apis.play.yeschess.app"

const usePlatform = () => {
  const { user } = useFirebaseAuth()

  const postCreatorFeedVideoV1 = useCallback(async () => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            name: 'New creator feed video',
            video: {
              timeline: [],
            },
            viewAsWhite: true,
            status: 'DRAFT',
          }),
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorFeedVideosV1 = useCallback(async (status) => {
    if (!user) throw new Error("Unable to usePlatform without a user")

    const queryString = status ? `?status=${status}` : ""

    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos${queryString}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorFeedVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const putCreatorFeedVideoV1 = useCallback(async (id, video) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos/${id}`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(video)
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const deleteCreatorFeedVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
        })
      })
  }, [user])

  const postCreatorPuzzleVideoV1 = useCallback(async () => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            name: 'New creator puzzle video',
            video: {
              timeline: [],
            },
            status: 'DRAFT',
          }),
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorPuzzleVideosV1 = useCallback(async (status) => {
    if (!user) throw new Error("Unable to usePlatform without a user")

    const queryString = status ? `?status=${status}` : ""

    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos${queryString}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorPuzzleVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const putCreatorPuzzleVideoV1 = useCallback(async (id, video) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos/${id}`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(video)
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const deleteCreatorPuzzleVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
        })
      })
  }, [user])

  const getRandomPuzzleV1 = useCallback(async (difficulty) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/puzzles/random?difficulty=${difficulty}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  return {
    postCreatorFeedVideoV1,
    getCreatorFeedVideoV1,
    getCreatorFeedVideosV1,
    putCreatorFeedVideoV1,
    deleteCreatorFeedVideoV1,
    postCreatorPuzzleVideoV1,
    getCreatorPuzzleVideoV1,
    getCreatorPuzzleVideosV1,
    putCreatorPuzzleVideoV1,
    deleteCreatorPuzzleVideoV1,
    getRandomPuzzleV1,
  }
}

export default usePlatform
