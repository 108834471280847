import {parse} from "@mliebelt/pgn-parser"
import {Chess} from "chess.js"

const usePgn = () => {

  const recursivelyCreateTimeline = (chess, timeline, moves) => {
    moves.forEach(move => {
      if (move.variations) {
        move.variations.forEach(variation => {
          const chess2 = new Chess(chess.fen())
          recursivelyCreateTimeline(chess2, timeline, variation)
        })
      }

      chess.move(move.notation.notation)
      timeline.push({
        ofs: null,
        fen: chess.fen(),
        arrows: [],
        squares: [],
      })
    })
  }

  const generateTimeline = (pgn) => {
    const parsed = parse(pgn, { startRule: "game" })

    const start = parsed.tags.FEN || undefined
    const chess = new Chess(start)

    const timeline = []
    timeline.push({
      ofs: 0,
      fen: chess.fen(),
      arrows: [],
      squares: [],
    })

    recursivelyCreateTimeline(chess, timeline, parsed.moves)

    return timeline
  }

  return {
    generateTimeline,
  }
}

export default usePgn