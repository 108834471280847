import {useEffect, useState} from "react";
import usePlatform from "../../hooks/usePlatform";
import {Button, Spinner, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

// todo remove status from here
const CreatorFeedVideosTable = ({ status }) => {
  const { getCreatorFeedVideosV1 } = usePlatform()
  const navigate = useNavigate()
  const [data, setData] = useState({ loading: true, error: false, data: null })

  const onOpenCreatorFeedVideo = (id) => {
    navigate(`/creator-feed-videos/${id}`)
  }

  useEffect(() => {
    getCreatorFeedVideosV1(status).then(json => {
      setData({loading: false, error: false, data: json})
    }).catch(error => {
      setData({loading: false, error: true, data: null})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <Table>
      <thead>
      <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Creator</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {data.loading ? (
        <tr>
          <td colSpan="6" style={{ textAlign: 'center' }}>
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" variant="primary" />
            </div>
          </td>
        </tr>
      ) : data.error ? (
        <tr>
          <td colSpan="6" style={{ textAlign: 'center' }}>There was an error</td>
        </tr>
      ) : !data.data || data.data.length === 0 ? (
        <tr>
          <td colSpan="6" style={{ textAlign: 'center' }}>No data to display</td>
        </tr>
      ) : data.data.map(entry => (
        <tr key={entry.id}>
          <td>{entry.id}</td>
          <td>{entry.title}</td>
          <td>{entry.creator.firstName || "Unknown"} {entry.creator.surname || "Unknown"}</td>
          <td>
            <Button size="sm" variant="secondary" onClick={() => { onOpenCreatorFeedVideo(entry.id) }}>Edit</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  )
}

export default CreatorFeedVideosTable
