import {Button, Container} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import usePlatform from "../../hooks/usePlatform"
import CreatorFeedVideosTable from "./CreatorFeedVideosTable"

const CreatorFeedVideosScreen = () => {
  const navigate = useNavigate()
  const { postCreatorFeedVideoV1 } = usePlatform()

  const onNewCreatorFeedVideo = () => {
    postCreatorFeedVideoV1().then(json => {
      navigate(`/creator-feed-videos/${json.id}`)
    }).catch(error => {
      console.error(error)
    })
  }

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-end">
        <Button className="mb-3" variant="primary" onClick={onNewCreatorFeedVideo}>Create</Button>
      </div>

      <CreatorFeedVideosTable />
    </Container>
  )
}

export default CreatorFeedVideosScreen
