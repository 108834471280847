import {Button, ButtonGroup, Col, Container, Row} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import usePlatform from "../../hooks/usePlatform"
import CreatorPuzzleVideosTable from "./CreatorPuzzleVideosTable"
import {useState} from "react";

const statuses = ["DRAFT", "REVIEW", "PUBLISHED"]

const CreatorPuzzleVideosScreen = () => {
  const navigate = useNavigate()
  const { postCreatorPuzzleVideoV1 } = usePlatform()
  const [status, setStatus] = useState("DRAFT")

  const onNewCreatorPuzzleVideo = () => {
    postCreatorPuzzleVideoV1().then(json => {
      navigate(`/creator-puzzle-videos/${json.id}`)
    }).catch(error => {
      console.error(error)
    })
  }

  return (
    <Container className="my-4">
      <Row>
        <Col xs="auto">
          <ButtonGroup className="me-2">
            {statuses.map(s => (
              <Button key={s} variant="secondary" disabled={status === s} onClick={() => {setStatus(s)}}>{s}</Button>
            ))}
          </ButtonGroup>
        </Col>

        <Col>
          <div className="d-flex justify-content-end">
            <Button className="mb-3" variant="primary" onClick={onNewCreatorPuzzleVideo}>Create</Button>
          </div>
        </Col>
      </Row>

      <CreatorPuzzleVideosTable status={status} />
    </Container>
  )
}

export default CreatorPuzzleVideosScreen
