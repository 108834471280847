import {createContext} from "react";
import {Spinner} from "react-bootstrap";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import AppNavBar from "./components/AppNavBar"
import useFirebaseAuth from "./hooks/useFirebaseAuth";
import LogInScreen from "./screens/LogIn/LogInScreen"
import CreatorFeedVideoScreen from "./screens/CreatorFeedVideos/CreatorFeedVideoScreen"
import CreatorFeedVideosScreen from "./screens/CreatorFeedVideos/CreatorFeedVideosScreen"
import CreatorPuzzleVideosScreen from "./screens/CreatorPuzzleVideos/CreatorPuzzleVideosScreen";
import CreatorPuzzleVideoScreen from "./screens/CreatorPuzzleVideos/CreatorPuzzleVideoScreen";

const authContext = createContext()

const AuthProvider = ({ children }) => {
  const auth = useFirebaseAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

const App = () => {
  const { user, initialising } = useFirebaseAuth()
  if (initialising) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary" />
      </div>
    )
  }

  return (
    <AuthProvider>
      <Router>
        {user !== null && (
          <AppNavBar />
        )}
        <Routes>
          <Route exact path="/" element={<LogInScreen />} />
          <Route exact path="/creator-feed-videos" element={<CreatorFeedVideosScreen />} />
          <Route exact path="/creator-feed-videos/:id" element={<CreatorFeedVideoScreen />} />
          <Route exact path="/creator-puzzle-videos" element={<CreatorPuzzleVideosScreen />} />
          <Route exact path="/creator-puzzle-videos/:id" element={<CreatorPuzzleVideoScreen />} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App;
