import {useCallback, useEffect, useState} from "react"
import {Accordion, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap"
import {useNavigate, useParams} from "react-router-dom"
import usePlatform from "../../hooks/usePlatform"
import VideoTimelineEditor from "../../components/VideoTimelineEditor"
import {Chess} from "chess.js"

const CreatorPuzzleVideoScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { deleteCreatorPuzzleVideoV1, getCreatorPuzzleVideoV1, putCreatorPuzzleVideoV1, getRandomPuzzleV1 } = usePlatform()
  const [state, setState] = useState({ loading: true, error: false, data: null })
  const [videoUrl, setVideoUrl] = useState("")
  const [puzzleDifficulty, setPuzzleDifficulty] = useState("")

  const onGet = useCallback(() => {
    getCreatorPuzzleVideoV1(id).then(json => {
      setState({ loading: false, error: false, data: json})
      setVideoUrl(json.video?.url ?? "")
    }).catch(error => {
      console.error(error)
      setState({ loading: false, error: true, data: null })
    })
  }, [id, getCreatorPuzzleVideoV1])

  const onUpdate = () => {
    putCreatorPuzzleVideoV1(id, state.data).then(json => {
      console.log(json)
    }).catch(error => {
      console.error(error)
    })
  }

  const onDelete = () => {
    deleteCreatorPuzzleVideoV1(id).then(() => {
      navigate(`/creator-puzzle-videos`)
    }).catch(error => {
      console.error(error)
    })
  }

  const parseMove = (move) => ({
    from: move.substring(0, 2),
    to: move.substring(2, 4),
    promotion: move.substring(4, 5),
  })

  const onLoadRandomPuzzle = () => {
    getRandomPuzzleV1(puzzleDifficulty).then(response => {
      setField("puzzle", response)
      setField("viewAsWhite", response.fen.split(" ")[1] === "b")
      const chess = new Chess(response.fen)

      const timeline = response.moves.map(move => {
        chess.move(parseMove(move))
        return {
          ofs: 0,
          fen: chess.fen(),
          arrows: [],
          squares: [],
        }
      })

      setField("video", { ...state.data.video, timeline })
    }).catch(error => {
      console.error(error)
    })
  }

  useEffect(() => {
    onGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, data: { ...prev.data, [field]: value } }))
  }

  return (
    <Container className="mt-3">
      {state.error === true && (
        <p>There was an error</p>
      )}

      {state.loading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      )}

      {!state.loading && !state.error && (
        <>
          <Row className="mb-3">
            <Col></Col>

            <Col>
              <div className="d-flex justify-content-end">
                <Button variant="success" className="me-2" onClick={onUpdate}>
                  Update
                </Button>

                <Button variant="danger" className="me-2" onClick={onDelete}>
                  Delete
                </Button>
              </div>
            </Col>
          </Row>

          <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Video Configuration</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control value={state.data.name} onChange={(event) => { setField("name", event.target.value) }}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Remote Video URL</Form.Label>
                      <Form.Control value={state.data.video?.url} onChange={(event) => {
                        setField("video", { ...state.data.video, url: event.target.value })
                        setVideoUrl(event.target.value)
                      }}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Video Duration</Form.Label>
                      <Form.Control value={state.data.video?.duration} readOnly/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select value={state.data.status} onChange={(event) => { setField("status", event.target.value) }}>
                        <option value="DRAFT">DRAFT</option>
                        <option value="REVIEW">REVIEW</option>
                        <option value="PUBLISHED">PUBLISHED</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>Local Video</Form.Label>
                      <Form.Control type="file" onInput={(event) => {
                        const file = event.target.files[0]
                        if (!file) return
                        const url = URL.createObjectURL(file);
                        setField("video", { ...state.data.video, url })
                      }} />
                      <Form.Text muted>Local videos will not be persisted.</Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Timeline Preload Configuration (Puzzle)</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Row>
                      <Col xs="auto">
                        <Form.Group className="mb-3">
                          <Form.Control placeholder="Puzzle Difficulty" value={puzzleDifficulty} onChange={(event) => { setPuzzleDifficulty(event.target.value) }}/>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Button variant="secondary" onClick={onLoadRandomPuzzle}>Load Random Puzzle</Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    {state.data.puzzle?.id && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Puzzle ID</Form.Label>
                          <Form.Control value={state.data.puzzle.id} readOnly disabled />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Puzzle FEN</Form.Label>
                          <Form.Control value={state.data.puzzle.fen} readOnly disabled />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Puzzle Difficulty</Form.Label>
                          <Form.Control value={state.data.puzzle.difficulty} readOnly disabled />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Puzzle Moves</Form.Label>
                          <Form.Control value={state.data.puzzle.moves.join(" ")} readOnly disabled />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <VideoTimelineEditor
            originalTimeline={state.data.video.timeline}
            videoUrl={videoUrl}
            viewAsWhite={state.data.viewAsWhite}
            onTimelineChange={timeline => {
              setField("video", { ...state.data.video, timeline })
            }}
            onVideoLoaded={duration => {
              setField("video", { ...state.data.video, duration })
            }}
          />
        </>
      )}
    </Container>
  );
}

export default CreatorPuzzleVideoScreen
