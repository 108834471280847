import {useState} from "react"
import {Chess} from "chess.js"

const useUserVideoTimeline = () => {
  const [timeline, setTimeline] = useState(null)
  const [timelineIndex, setTimelineIndex] = useState(0)

  const initTimeline = (timeline) => {
    setTimeline(timeline)
    setTimelineIndex(0)
  }

  const applyOffset = (offset) => {
    if (timeline.length) {
      const copyTimeline = [...timeline]
      copyTimeline[timelineIndex].ofs = Math.round(offset * 1000)
      setTimeline(copyTimeline)

      if (timelineIndex < copyTimeline.length - 1) {
        setTimelineIndex(timelineIndex + 1)
      }
    }
  }

  const addRemoveSquare = (square) => {
    const copyTimeline = [...timeline]
    const squares = copyTimeline[timelineIndex].squares
    if (squares.includes(square)) {
      squares.splice(squares.indexOf(square), 1)
    } else {
      squares.push(square)
    }
    setTimeline(copyTimeline)
  }

  const setArrows = (arrows) => {
    const copyTimeline = [...timeline]
    copyTimeline[timelineIndex].arrows = arrows
    setTimeline(copyTimeline)
  }

  const insertTimelineEntry = (index) => {
    if (timeline.length) {
      const copyTimeline = [...timeline]
      const copyEntry = copyTimeline[timelineIndex]

      copyTimeline.splice(index, 0, {
        ofs: null,
        fen: copyEntry.fen,
        arrows: copyEntry.arrows,
        squares: copyEntry.squares,
      })
      setTimeline(copyTimeline)
    }
  }

  const insertBefore = () => {
    insertTimelineEntry(timelineIndex)
  }

  const insertAfter = () => {
    insertTimelineEntry(timelineIndex + 1)
  }

  const moveDown = () => {
    if (timelineIndex < timeline.length) {
      const copyTimeline = [...timeline]
      const copyEntry = copyTimeline[timelineIndex]
      const copyEntryAfter = copyTimeline[timelineIndex + 1]

      copyTimeline.splice(timelineIndex, 2, copyEntryAfter, copyEntry)
      setTimeline(copyTimeline)
      setTimelineIndex(timelineIndex + 1)
    }
  }

  const moveUp = () => {
    if (timelineIndex > 0) {
      const copyTimeline = [...timeline]
      const copyEntry = copyTimeline[timelineIndex]
      const copyEntryBefore = copyTimeline[timelineIndex - 1]

      copyTimeline.splice(timelineIndex - 1, 2, copyEntry, copyEntryBefore)
      setTimeline(copyTimeline)
      setTimelineIndex(timelineIndex - 1)
    }
  }

  const move = (algebraicFrom, algebraicTo) => {
    const current = timeline.length ? new Chess(timeline[timelineIndex].fen) : new Chess()

    try {
      current.move({from: algebraicFrom, to: algebraicTo}) // todo promotions?

      const copyTimeline = [...timeline]
      copyTimeline.splice(timelineIndex + 1, 0, {
        ofs: null,
        fen: current.fen(),
        arrows: [],
        squares: [],
      })

      setTimeline(copyTimeline)

      if (timeline.length) {
        setTimelineIndex(timelineIndex + 1)
      }
    } catch (error) {
      console.log("Error making move. Probably not a legal move.")
      console.error(error)
    }
  }

  const remove = () => {
    const copyTimeline = [...timeline]

    if (timelineIndex === copyTimeline.length - 1) {
      setTimelineIndex(timelineIndex - 1)
    }

    copyTimeline.splice(timelineIndex, 1)
    setTimeline(copyTimeline)
  }

  const goBackwards = () => {
    if (timelineIndex > 0) {
      setTimelineIndex(timelineIndex - 1)
    }
  }

  const goForwards = () => {
    if (timelineIndex < timeline.length - 1) {
      setTimelineIndex(timelineIndex + 1)
    }
  }

  const updateTimelineIndexByOffset = (seconds) => {
    const before = timeline
      .map((entry, index) => ({ ...entry, index }))
      .filter(entry => entry.ofs !== null && entry.ofs < seconds * 1000)

    if (before.length > 0) {
      const last = before[before.length - 1]
      setTimelineIndex(last.index)
    }
  }

  // todo maybe "onClickTimeline(index)" // change video timestamp and load chessboard

  return {
    timeline,
    timelineIndex,
    setTimelineIndex,
    initTimeline,
    applyOffset,
    addRemoveSquare,
    setArrows,
    insertBefore,
    insertAfter,
    updateTimelineIndexByOffset,
    moveDown,
    moveUp,
    move,
    remove,
    goForwards,
    goBackwards,
  }
}

export default useUserVideoTimeline