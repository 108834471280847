import {useEffect, useRef, useState} from "react"
import {Button, ButtonGroup, Col, Row, Table} from "react-bootstrap"
import {Chessboard} from "react-chessboard"
import useUserVideoTimeline from "../hooks/useUserVideoTimeline";

const VideoTimelineEditor = ({ originalTimeline, videoUrl, viewAsWhite, onTimelineChange, onVideoLoaded }) => {
  const [mode, setMode] = useState("PREVIEW")
  const videoRef = useRef()
  const {
    timeline,
    timelineIndex,
    setTimelineIndex,
    initTimeline,
    applyOffset,
    addRemoveSquare,
    setArrows,
    insertBefore,
    insertAfter,
    updateTimelineIndexByOffset,
    moveDown,
    moveUp,
    move,
    remove,
    goForwards,
    goBackwards,
  } = useUserVideoTimeline()

  useEffect(() => {
    initTimeline(originalTimeline)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalTimeline])

  useEffect(() => {
    videoRef.current?.load()
  }, [videoUrl])

  return (
    <>
      <div className="my-3">
        <Row>
          <Col xs="auto">
            <ButtonGroup className="me-2">
              <Button variant="secondary" disabled={mode==="EDIT"} onClick={() => {setMode("EDIT")}}>Edit Mode</Button>
              <Button variant="secondary" disabled={mode==="PREVIEW"} onClick={() => {setMode("PREVIEW")}}>Preview Mode</Button>
            </ButtonGroup>
          </Col>

          <Col>
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                onClick={() => { remove() }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Remove
              </Button>

              <Button
                variant="primary"
                onClick={() => { insertBefore() }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Before+
              </Button>

              <Button
                variant="primary"
                onClick={() => { insertAfter() }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                After+
              </Button>

              <Button
                variant="primary"
                onClick={() => { moveDown() }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Move Down
              </Button>

              <Button
                variant="primary"
                onClick={() => { moveUp() }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Move Up
              </Button>

              <Button
                variant="primary"
                onClick={() => { applyOffset(videoRef.current.currentTime) }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Apply Offset
              </Button>

              <Button
                variant="success"
                onClick={() => { onTimelineChange(timeline) }}
                style={{ marginRight: "5px" }}
                disabled={mode === "PREVIEW"}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mb-3">
        <Col>
          <Row>
            <Col xs="4">
              <video
                ref={videoRef}
                width='100%'
                controls
                onLoadedMetadata={() => {
                  onVideoLoaded(videoRef.current.duration)
                }}
                onTimeUpdate={(event) => {
                  if (mode === "PREVIEW") {
                    updateTimelineIndexByOffset(event.target.currentTime)
                  }
                }}
              >
                <source src={videoUrl} />
                Your browser does not support the video tag.
              </video>

              {timeline && timeline.length > 0 ? (
                  <Chessboard
                    id="yeschess"
                    position={timeline[timelineIndex].fen}
                    customArrows={timeline[timelineIndex].arrows}
                    onArrowsChange={setArrows}
                    onSquareRightClick={addRemoveSquare}
                    onPieceDrop={move}
                    boardOrientation={viewAsWhite ? 'white' : 'black'}
                  />
                ) :
                <>
                  <Chessboard
                    id="yeschess"
                    onPieceDrop={move}
                    boardOrientation={viewAsWhite ? 'white' : 'black'}
                  />
                </>
              }

              <ButtonGroup className="my-3">
                <Button variant="secondary" onClick={goBackwards}>&larr;</Button>
                <Button variant="secondary" onClick={goForwards}>&rarr;</Button>
              </ButtonGroup>
            </Col>

            <Col xs="8">
              <div style={{ height: "750px", width: '100%', overflowY: "scroll" }} className="mb-3">
                <Table size="sm">
                  <thead>
                  <tr>
                    <th>Ofs</th>
                    <th>FEN</th>
                    <th>Arrows</th>
                    <th>Squares</th>
                  </tr>
                  </thead>
                  <tbody>
                  {timeline && timeline.map((entry, index) => (
                    <tr
                      key={index}
                      className={index === timelineIndex ? "table-success" : ""}
                      onClick={() => { setTimelineIndex(index) }}
                    >
                      <td>{entry.ofs}</td>
                      <td><small>{entry.fen}</small></td>
                      <td>{entry.arrows.length === 0 ? "-" : entry.arrows.join(", ")}</td>
                      <td>{entry.squares.length === 0 ? "-" : entry.squares.join(", ")}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )

}

export default VideoTimelineEditor
