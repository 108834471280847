import {useCallback, useEffect, useState} from "react"
import {Accordion, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap"
import {useNavigate, useParams} from "react-router-dom"
import usePlatform from "../../hooks/usePlatform"
import usePgn from "../../hooks/usePgn"
import VideoTimelineEditor from "../../components/VideoTimelineEditor";

const CreatorFeedVideoScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { deleteCreatorFeedVideoV1, getCreatorFeedVideoV1, putCreatorFeedVideoV1 } = usePlatform()
  const [state, setState] = useState({ loading: true, error: false, data: null })
  const [videoUrl, setVideoUrl] = useState("")
  const [pgn, setPgn] = useState("")
  const { generateTimeline } = usePgn()

  const onGet = useCallback(() => {
    getCreatorFeedVideoV1(id).then(json => {
      setState({ loading: false, error: false, data: json})
      setPgn(json.pgn)
      setVideoUrl(json.video?.url)
    }).catch(error => {
      console.error(error)
      setState({ loading: false, error: true, data: null })
    })
  }, [id, getCreatorFeedVideoV1])

  const onUpdate = () => {
    putCreatorFeedVideoV1(id, state.data).then(json => {
      console.log(json)
    }).catch(error => {
      console.error(error)
    })
  }

  const onDelete = () => {
    deleteCreatorFeedVideoV1(id).then(() => {
      navigate(`/creator-feed-videos`)
    }).catch(error => {
      console.error(error)
    })
  }

  useEffect(() => {
    onGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, data: { ...prev.data, [field]: value } }))
  }

  const onLoadPgn = () => {
    setField("pgn", pgn)
    const generated = generateTimeline(pgn)
    setField("video", { ...state.data.video, timeline: generated })
  }

  return (
    <Container className="mt-3">
      {state.error === true && (
        <p>There was an error</p>
      )}

      {state.loading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      )}

      {!state.loading && !state.error && (
        <>
          <Row className="mb-3">
            <Col></Col>

            <Col>
              <div className="d-flex justify-content-end">
                <Button variant="success" className="me-2" onClick={onUpdate}>
                  Update
                </Button>

                <Button variant="danger" className="me-2" onClick={onDelete}>
                  Delete
                </Button>
              </div>
            </Col>
          </Row>

          <Accordion className="mb-3">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Video Configuration</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control value={state.data.title} onChange={(event) => { setField("title", event.target.value) }}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Video URL</Form.Label>
                      <Form.Control value={state.data.video?.url} onChange={(event) => {
                        setField("video", { ...state.data.video, url: event.target.value })
                      }}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Video Duration</Form.Label>
                      <Form.Control value={state.data.video?.duration} readOnly/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Orientation</Form.Label>
                      <Form.Select value={state.data.viewAsWhite ? "white" : "black"} onChange={(event) => { setField("viewAsWhite", event.target.value === "white") }}>
                        <option value="white">View as White</option>
                        <option value="black">View as Black</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={5} value={state.data.description} onChange={(event) => { setField("description", event.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Thumbnail URL</Form.Label>
                      <Form.Control value={state.data.thumbnailUrl} onChange={(event) => { setField("thumbnailUrl", event.target.value) }}/>
                    </Form.Group>

                    {state.data.thumbnailUrl ? (
                      <img className="img-fluid" src={state.data.thumbnailUrl} alt="" />
                    ) : (
                      <p>No thumbnail to load.</p>
                    )}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Timeline Preload Configuration (PGN)</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>PGN</Form.Label>
                  <Form.Control as="textarea" rows={10} value={state.data.pgn} onChange={event => {
                    setPgn(event.target.value)
                  }}/>
                </Form.Group>
                <Button variant="secondary" onClick={onLoadPgn}>
                  Load PGN
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <VideoTimelineEditor
            originalTimeline={state.data.video.timeline}
            videoUrl={videoUrl}
            viewAsWhite={state.data.viewAsWhite}
            onTimelineChange={timeline => {
              setField("video", { ...state.data.video, timeline })
            }}
            onVideoLoaded={duration => {
              setField("video", { ...state.data.video, duration: duration * 1000 })
            }}
          />
        </>
      )}
    </Container>
  );
}

export default CreatorFeedVideoScreen
